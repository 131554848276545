import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import TextImageBlock from "../components/textImageBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"

import {
  LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Custom Offset and Letterpress Printing in Atlanta | Pressroom Alchemy"
        ogDescription="Discover Atlanta's premier print solutions! Explore custom printing, offset, foil stamping, and more. Join us on our creative journey!"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Custom Printing in Atlanta</h1>
            <h2>Offset and Letterpress Printing, Foil Stamping, Embossing/Debossing and Custom Binding</h2>
            <p>
              We are your destination for exquisite print solutions in Atlanta! Our services include custom printing, offset and letterpress printing, foil stamping, embossing/debossing, and custom binding. With a passion for precision and a commitment to creativity, we're dedicated to bringing your unique ideas to life through the artistry of print. Stay tuned as we put the finishing touches on our website, and in the meantime, feel free to reach out to us for all your printing needs. We can't wait to be your trusted partner in turning your visions into stunning printed reality.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Get A Quote",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img src="/images/logo-full-blue.png" alt="Custom Offset and Letterpress Printing" />
          </div>
        }
      />
      <TextImageBlock
        image={
          <img
            src="/images/heidleberg-family.jpg"
            alt="Heidelberg Printing Presses"
          />
        }
        imageGrow
        textPadded
        text={
          <div>
            <h2>
              INK LETTERPRESSES
            </h2>
						<ul>
							<li>1954 Heidelberg 13x18 Windmill</li>
							<li>1958 Heidelberg 10x15 Windmill</li>
							<li>1956 Heidelberg 21x28 Cylinder</li>
							<li>1948 Chandler & Price 14x20 hand fed clamshell</li>
						</ul>
          </div>
        }
      />
      <TextImageBlock
        image={
          <img
            src="/images/ryobi-family.jpg"
            alt="Ryobi 4-color Offset Prin Presses"
          />
        }
        imageGrow
				imageRight
        textPadded
        text={
          <div>
            <h2>
							OFFSET PRESSES
            </h2>
						<ul>
							<li>2004 Ryobi 524HE 14x20 four color</li>
							<li>2004 Ryobi 3304HA 12x18 four color</li>
						</ul>
          </div>
        }
      />
      <TextImageBlock
        image={
          <img
            src="/images/kluge-family.jpg"
            alt="Kluge Printing Presses"
          />
        }
        imageGrow
        textPadded
        text={
          <div>
            <h2>
							FOIL STAMPING, EMBOSSING & DIE CUTTING PRESSES
            </h2>
						<ul>
							<li>1989 Kluge EHD 14x22</li>
							<li>1979 Kluge EHD 14x22</li>
							<li>1989 Kluge EHE 14x22</li>
						</ul>
          </div>
        }
      />
      <TextImageBlock
        image={
          <img
            src="/images/fullshopfromheidlebergs.jpg"
            alt="Pressroom Alchemy"
          />
        }
        imageGrow
        imageRight
        textPadded
        text={
          <div>
            <h2>
              Set Up A Tour
            </h2>
            <p>
							We'd love to show you around the shop. Contact us to set up a tour.
						</p>
		        <FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule A Tour",
		            size: "tiny",
		            icon: "chat",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
